<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between">
      <div class="col-12 col-md-auto">
        <h1>{{ $t("platform.create_new") }}</h1>
      </div>
    </div>
    <div class="sep _h10"></div>
    <div class="row" v-if="hasAccess">
      <div
        v-for="(el, ind) in categories"
        :key="ind"
        class="col-12 col-md-6 d-md-flex mb-4"
      >
        <div class="section">
          <h2>{{ $t(`platform.${el}.title`) }}</h2>
          <div class="entry-content pt-2">
            <ul>
              <li class="pb-2">{{ $t(`platform.${el}.sub_1`) }}</li>
              <li class="pb-2">{{ $t(`platform.${el}.sub_2`) }}</li>
              <li class="pb-2">
                {{ $t(`platform.${el}.sub_3`) }}
              </li>
            </ul>
          </div>
          <button
            @click="checkAccess(el)"
            class="btn mb-2 _mob100"
            :class="{ '_bg-green mb-2': el === 'site' }"
          >
            {{ $t(`platform.${el}.create`) }}
          </button>
        </div>
      </div>
    </div>
    <no-access v-if="!hasAccess" />
  </div>
</template>
<script>
import NoAccess from '@/components/NoAccess.vue'
import { mapGetters } from 'vuex';
export default {
  name: "ChoosePlatform",
  props: {
    setPlatform: {
      type: Function,
      default: function () {},
    },
  },
  data() {
    return {
      categories: ["arbitrage", "site"],
      hasAccess: true
    };
  },
  computed: {
     ...mapGetters({
      user: "user/getUser",
    }),
  },
  components:{
    NoAccess
  },
  methods: {
    checkAccess(el) {
      if (!this.user.confirm) {
   
        this.hasAccess = false
        return
      }
      this.$emit('setPlatform', el === 'arbitrage' ? 0 : 1)
    }
  }
};
</script>