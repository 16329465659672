<template>
  <div class="no-access-block">
    <img :src="require('@/assets/images/icons/no-access.svg')" class="no-access">
    <h4> {{$t('no_access')}}</h4>
    <button class="btn-outline mr-3" @click="openTg('Leo31PH')">@Leo31PH</button>
    <button class="btn-outline" @click="openTg('Robert_ph')">@Robert_ph</button>
  </div>
</template>

<script>
export default {
  methods: {
    openTg(username) {
      window.open(`https:///telegram.me/${username}`)
    }
  }
}
</script>

<style scoped lang="scss">
.no-access-block {
  height: 70vh;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  border-radius: 20px;
  background: #F7F7F9;
  padding: 30px;
  
  img {
    margin: 120px auto 0;
    display: block;
    width: 120px;
    height: auto;
  }
  h4 {
    font-size: 26px;
    padding-top: 20px;
    padding-bottom: 40px;
  }
  .btn-outline {
    background: transparent;
    color: #5084B5;
    border: 1px solid #5084B5;
    padding: 10px 20px;
    border-radius: 50px;
    &:hover {
      background: #5084B5;
      color: #fff;
    }
  }
}

</style>